import type { BadgeColor } from '#ui/types'
import type { InvoiceType } from '~/types/invoices'

export const badgeTypes: Record<
  InvoiceType,
  { color: BadgeColor; label: string }
> = {
  invoice_receipt: { color: 'green', label: 'Fatura-Recibo' },
  invoice: { color: 'amber', label: 'Fatura' },
  receipt: { color: 'fuchsia', label: 'Recibo' },
  credit_note: { color: 'red', label: 'Nota de Crédito' },
  debit_note: { color: 'blue', label: 'Nota de Débito' },
}
